const axios = require('axios');
import store from '../store';
const url = 'https://api64.ipify.org?format=json';
let ip;

const API = `${process.env.VUE_APP_BROOM_API_ENDPOINT}`;

export const handleLogout = () => {
  store.store.commit('cleanStore');
  window.location.reload();
}
export const login = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_BROOM_API_ENDPOINT}admin-login`, data
    );

    await axios.get(url)
      .then(res => {
        ip = res.data.ip;
      });

    if (response.status === 200) { //check reponse from the API
      //set the user info if login was correct
      store.store.commit('setUserLogged', {
        name: response.data.name,
        userId: response.data.userId,
        token: response.data.token,
        ipClient: ip,
      });
      return true;
    } else {
      return false;
    }

  } catch (e) {
    console.log(e);
  }
};


export const getHeaders = () => {
  //this header must be always passed when requesting the API (except for login)
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${store.store.state.userInfo.token}`
  }
}

export const getHeadersImg = () => {
  //this header must be always passed when requesting the API (except for login)
  return {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${store.store.state.userInfo.token}`
  }
}

export const saveAdminToken = async (data) => {
  try {

    const APISend = API + 'saveTokenAdmin';

    return await axios.post(APISend, data);
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}
