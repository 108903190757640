import VueRouter from "vue-router";
import routes from "./routes";
import store from '../store';
// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});
router.beforeEach((to,from,next)=>{
  //redirect to login page when it is not logged
  const isAuthenticated = localStorage.getItem('broom-app-isAuthenticated')==="true";
  if (to.name !== 'login' && !isAuthenticated) next({ name: 'login' })
  else if((to.name === 'login') && isAuthenticated){
    next({name: '/'})
  }
  else next();
});
export default router;
