<template>
  <div class="wrapper">
    <side-bar v-if="showMenuOptions">
      <template slot="links">
        <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-chart-pie-36"/>
        <sidebar-link to="/maps" :name="$t('sidebar.maps')" icon="tim-icons icon-pin"/>
        <sidebar-link to="/customers" :name="$t('sidebar.customer')" icon="tim-icons icon-single-02"/>
        <sidebar-link to="/broomer" :name="$t('sidebar.broomers')" icon="tim-icons icon-delivery-fast"/>
        <sidebar-link to="/orders" :name="$t('sidebar.orders')" icon="tim-icons icon-cart"/>
        <sidebar-link to="/notification" :name="$t('sidebar.notification')" icon="tim-icons icon-send"/>
        <sidebar-link to="/appErrors" :name="$t('sidebar.appErrors')" icon="tim-icons icon-chart-bar-32"/>
        <sidebar-link to="/promotion" :name="$t('sidebar.promotion')" icon="tim-icons icon-send"/>
        <sidebar-link to="/transaction" :name="$t('sidebar.transaction')" icon="tim-icons icon-money-coins"/>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar v-if="showMenuOptions"></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  data() {
    return {
      showMenuOptions: false //hide menu options when login page
    }
  },
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
  },
  created() {
    this.showMenuOptions = this.$route.fullPath !== '/login';
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
