import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dashboard.vue");
const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () => import("@/pages/Notifications/SendNotification");
const AppErrors = () => import("@/pages/AppError/AppErrorViews.vue");
const Promotions = () => import("@/pages/Promotion/indexPromotion.vue");
const CreatePromotion = () => import("@/pages/Promotion/CreatePromotions");
const EditPromotion = () => import("@/pages/Promotion/EditPromotions");
const Icons = () => import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");
const Login = () => import(/* webpackChunkName: "common" */ "@/pages/Authentication/Login.vue");
const Customers = () => import(/* webpackChunkName: "common" */ "@/pages/Customers/ListCustomers.vue");
const EditCustomer = () => import(/* webpackChunkName: "common" */ "@/pages/Customers/EditCustomer.vue");
const OrderCustomer = () => import("@/pages/Customers/OrderCustomer.vue");
const Broomer = () => import("@/pages/Broomers/ListBroomers");
const CreateBroomer = () => import("@/pages/Broomers/CreateBroomers");
const EditBroomer = () => import("@/pages/Broomers/EditBroomers");
const OrderBroomer = () => import("@/pages/Broomers/OrderBroomers");
const Orders = () => import("@/pages/Orders/ListOrders");
const ShowOrders = () => import("@/pages/Orders/ShowOrders");
const UpdateOrder = () => import("@/pages/Orders/UpdateOrder");
const PayBroomer = () => import("@/pages/Broomers/PayBroomers");

const Transaction = () => import("@/pages/Transaction/ListTransaction")

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard
      },
      {
        path: "profile",
        name: "profile",
        component: Profile
      },
      {
        path: "notification",
        name: "notification",
        component: Notifications
      },
      {
        path: "appErrors",
        name: "appErrors",
        component: AppErrors
      },
      {
        path: "promotion",
        name: "promotion",
        component: Promotions
      },
      {
        path: "createPromotion",
        name: "Create Promotion",
        component: CreatePromotion
      },{
      path: "editPromotion/:id",
      name: "Edit Promotion",
      component: EditPromotion
    },
      {
        path: "icons",
        name: "icons",
        component: Icons
      },
      {
        path: "maps",
        name: "maps",
        component: Maps
      },
      {
        path: "typography",
        name: "typography",
        component: Typography
      },
      {
        path: "table-list",
        name: "table-list",
        component: TableList
      },
      {
        path: "login",
        name: "login",
        component: Login
      },
      {
        path: "broomer",
        name: "List broomer",
        component: Broomer
      },
      {
        path: "createBroomer",
        name: "Create Broomer",
        component: CreateBroomer
      },
      {
        path: "editBroomer/:id",
        name: "Edit Broomer",
        component: EditBroomer
      },
      {
        path: "customers",
        name: "customers",
        component: Customers
      },
      {
        path: "editCustomer/:id",
        name: "Edit Customer",
        component: EditCustomer
      },
      {
        path: "orderBroomer/:id",
        name: "Orders Broomer",
        component: OrderBroomer
      },
      {
        path: "orders",
        name: "List Orders",
        component: Orders
      },
      {
        path: "showOrders/:id",
        name: "Show Orders",
        component: ShowOrders
      },
      {
        path: "orderCustomer/:id",
        name: "List Orders",
        component: OrderCustomer
      },
      {
        path: "updateOrder/:id",
        name: "Update Order",
        component: UpdateOrder
      },
      {
        path: "payBroomer/:id",
        name: "Pay to broomer",
        component: PayBroomer
      },
      {
        path: "transaction",
        name: "List Transaction",
        component: Transaction
      }
    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
