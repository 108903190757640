import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

const firebaseConfig = {
  apiKey: "AIzaSyCG2e5BaTSadBUtZGKYggd4OCT0blFstso",
  authDomain: "broom-app-1b699.firebaseapp.com",
  databaseURL: "https://broom-app-1b699-default-rtdb.firebaseio.com",
  projectId: "broom-app-1b699",
  storageBucket: "broom-app-1b699.appspot.com",
  messagingSenderId: "881648815860",
  appId: "1:881648815860:web:bcf0d86ec5ed2608abd8a1"
};

firebase.initializeApp(firebaseConfig)

export default firebase.messaging()
