import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    //add here all the state variables
    userInfo: null,
    isAuthenticated: false,
  },
  mutations: {
    initialiseStore(state){
      //initialize all data from localstorage
      state.isAuthenticated = localStorage.getItem('broom-app-isAuthenticated') === 'true';
      if(state.isAuthenticated){
        state.userInfo = JSON.parse(localStorage.getItem('broom-app-userInfo')) ;
      }
      console.log("load data from localstorage");
      state.userInfo = JSON.parse(localStorage.getItem('broom-app-userInfo'));
    },
    cleanStore(state){
      //clean all the store
      state.userInfo = null;
      state.isAuthenticated = false;
      localStorage.removeItem('broom-app-userInfo');
      localStorage.removeItem('broom-app-isAuthenticated');
    },
    setUserLogged(state, info){
      state.userInfo = info;
      state.isAuthenticated = true;
      localStorage.setItem('broom-app-userInfo', JSON.stringify(state.userInfo));
      localStorage.setItem('broom-app-isAuthenticated',"true");
    },

  },
  getters: {
    //means to add validation functions
    validateToken: state => {
      //validate if token exists and it is still active
      return  state.token && state.expiration && new Date()<new Date(state.expiration*1000);
    },
    isAuthenticated: state =>{
      return state.isAuthenticated;
    }
  }
})
export default {
  store
}
